<template>
  <div class="guarantee">
    <div class="guarantee-title">
      30 Day <br v-if="isTablet" />
      Money Back Guarantee
    </div>
    <div class="guarantee-box">
      <div class="guarantee-box__img">
        <img src="@/assets/img/paywall/guarantee.png" alt="" />
      </div>
      <div class="guarantee-box__text">
        If you are not satisfied with your plan or the Mental Future app please
        let us know and we will refund 100% of your payment.
      </div>
    </div>
  </div>
</template>

<script setup>
import useWindowResize from '@/utils/useWindowResize'

const { isTablet } = useWindowResize()
</script>

<style lang="scss" scoped>
.guarantee {
  margin-bottom: 104px;
  color: #fff;

  &-title {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    font-size: 54px;
    font-weight: 700;
    margin-bottom: 76px;
  }

  &-box {
    display: flex;
    align-items: center;
    gap: 100px;
    justify-content: center;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 80%;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.1);
    }

    &__img {
      min-width: 245px;
      height: 245px;
      display: flex;
    }

    &__text {
      max-width: 507px;
      width: 100%;
      font-family: var(--manrope-mediun);
      font-size: 20px;
      font-weight: 500;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 56px;

    &-title {
      font-size: 32px;
      margin-bottom: 32px;
    }

    &-box {
      flex-direction: column;
      gap: 16px;
      padding: 24px;

      &::before {
        bottom: 0;
        height: 65%;
        z-index: -1;
      }

      &__img {
        min-width: inherit;
        width: 120px;
        height: 120px;
      }

      &__text {
        font-size: 16px;
        text-align: center;
      }
    }
  }
}
</style>
