<template>
  <footer class="paywall-footer">
    <div class="paywall-footer__logo">
      <router-link to="/">
        <img src="@/assets/img/footer-logo.svg" />
      </router-link>
    </div>
    <div class="paywall-footer__nav">
      <router-link :to="{ name: 'TermsAndConditions' }"
        >Terms of Use</router-link
      >
      <router-link :to="{ name: 'PrivacyAndPolicy' }"
        >Privacy Policy</router-link
      >
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.paywall-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 40px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.5);

  &__nav {
    color: #fff;
    font-family: var(--manrope-mediun);
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 40px;
  }

  &__logo {
    max-width: 200px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    padding: 32px 0;

    &__logo {
      max-width: 140px;
    }

    &__nav {
      font-size: 14px;
    }
  }
}
</style>
