<template>
  <div class="cycle">
    <div class="cycle-title">
      Created by leading scientists, expert hypnotherapists and yogis
    </div>
    <div class="cycle-desc">
      The Mental Future Method is a unique framework, combining the best aspects
      of CBT and mindfulness and presenting it in bite-sized chunks, fitting
      into any schedule and routine. Using the power of hypnotherapy this
      mind-body intervention creates a state of focused attention and increased
      suggestability in order to reframe your brain patterns and help you reach
      your goals.
    </div>
    <div class="cycle-img">
      <img src="@/assets/img/paywall/cycle.png" alt="" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cycle {
  margin-bottom: 104px;
  color: #fff;
  &-title {
    max-width: 791px;
    width: 100%;
    margin: 0 auto 24px;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
  }

  &-desc {
    max-width: 1062px;
    width: 100%;
    margin: 0 auto 104px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
  }

  &-img {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    margin-bottom: 56px;

    &-title {
      font-size: 24px;
      line-height: 33px;
      max-width: 333px;
    }
    &-desc {
      font-size: 16px;
      margin: 0 auto 56px;
    }
  }
}
</style>
