<template>
  <div class="faq">
    <div class="faq-title">
      A personalized program that will change the way you think and improve your
      life
    </div>
    <div class="faq-box">
      <a-collapse v-model:activeKey="activeKey" expand-icon-position="right">
        <template #expandIcon="{ isActive }">
          <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{ rotate: isActive }" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.994 6.786L2.044.83a1.12 1.12 0 00-1.589 0 1.13 1.13 0 000 1.593l6.742 6.75c.426.427 1.11.436 1.55.033l6.79-6.778c.22-.22.327-.511.327-.797 0-.286-.107-.576-.328-.797a1.12 1.12 0 00-1.588 0L7.994 6.786z" fill="#fff"/></svg>
        </template>
        <a-collapse-panel v-for="(q, i) in questions" :key="i">
          <template #header>
            <div class="faq-box__header">
              <div class="faq-box__header-img">
                <img :src="q.icon" alt="" />
              </div>

              {{ q.question }}
            </div>
          </template>
          <p class="faq-box__answer">{{ q.answer }}</p>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <button
      class="paywall-button faq-button"
      @click="$emit('clickButton')"
      v-if="!hideButton"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M23.2 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM10 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM1 1h4.2L10 21.4h13.2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.437 19H25l3.6-10.8H6.892" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
      Get My Plan
    </button>
  </div>
</template>

<script setup>
import { questions } from '@/data/paywallData.js'
import { ref } from 'vue'

const activeKey = ref([])
const props = defineProps({
  hideButton: {
    type: Boolean,
    default: false,
  },
})
</script>

<style lang="scss" scoped>
.faq {
  margin-bottom: 104px;

  &-button {
    display: flex;
    margin: 0 auto;
  }

  &-title {
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    max-width: 963px;
    width: 100%;
    margin: 0 auto 72px;
  }
  &-box {
    max-width: 726px;
    padding: 10px 0;
    margin: 0 auto 72px;

    &__header {
      display: flex;
      align-items: center;
      gap: 20px;
      font-weight: 700;
      font-size: 24px;
      color: #fff;
      &-img {
        width: 44px;
        height: 44px;
        display: flex;
      }
    }

    &__answer {
      color: #fff;
      font-weight: 400;
      font-size: 20px;
    }

    .ant-collapse {
      background-color: inherit;
      border: none;

      display: grid;
      gap: 20px;
    }

    .ant-collapse > .ant-collapse-item {
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.1);
      border: none;
    }

    :deep(.ant-collapse-content) {
      background-color: inherit;
      border-top: none;
      background: none;
    }

    :deep(.ant-collapse-icon-position-right
        > .ant-collapse-item
        > .ant-collapse-header
        .ant-collapse-arrow) {
      width: 24px;
      height: 24px;
      right: 24px;
    }

    :deep(.ant-collapse-content > .ant-collapse-content-box) {
      padding: 0 80px 24px 85px;
    }

    :deep(.ant-collapse-icon-position-right
        > .ant-collapse-item
        > .ant-collapse-header
        .ant-collapse-arrow.rotate) {
      transform: rotate(180deg);
    }

    :deep(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
      padding: 20px;
    }
    :deep(.ant-collapse > .ant-collapse-item-active > .ant-collapse-header) {
      padding: 20px 20px 8px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 56px;
    &-title {
      font-size: 20px;
      margin-bottom: 32px;
    }

    &-box {
      margin-bottom: 32px;
      &__header {
        font-size: 16px;
        gap: 16px;
        &-img {
          width: 32px;
          height: 32px;
        }
      }
      :deep(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
        padding: 16px;
      }
      :deep(.ant-collapse > .ant-collapse-item-active > .ant-collapse-header) {
        padding: 16px 16px 8px;
      }
      &
        .ant-collapse-icon-position-right
        > .ant-collapse-item
        > .ant-collapse-header
        .ant-collapse-arrow {
        width: 20px;
        height: 20px;
      }
      &__answer {
        font-size: 16px;
      }
      :deep(.ant-collapse-content > .ant-collapse-content-box) {
        padding: 0 55px 24px 65px;
      }
    }
  }
}
</style>
