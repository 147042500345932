<template>
  <div class="timer-wrapper">
    <div class="timer">
      <div class="timer-img">
        <img src="@/assets/img/paywall/timer.png" alt="" />
      </div>
      <div class="timer-title">
        {{ title }}
        <div class="timer-count">
          <div class="timer-count__item">{{ formattedMinutes }}</div>
          :
          <div class="timer-count__item">{{ formattedSeconds }}</div>
        </div>
      </div>
      <button
        class="paywall-button timer-button"
        @click="$emit('clickButton')"
        v-if="!hideButton"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none" v-bind:svg-inline="''" v-if="!isTablet" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M23.2 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM10 25a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM1 1h4.2L10 21.4h13.2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.437 19H25l3.6-10.8H6.892" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        {{ btnText }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import useWindowResize from '@/utils/useWindowResize'

const props = defineProps({
  title: {
    type: String,
    default: 'Offer expires in:',
  },
  hideButton: {
    type: Boolean,
    default: false,
  },
  btnText: {
    type: String,
    default: 'Get My Plan',
  },
})

const { isTablet } = useWindowResize()

const targetTime = ref(new Date())
const storedStartTime = sessionStorage.getItem('timerStartTime')

if (storedStartTime) {
  targetTime.value.setTime(parseInt(storedStartTime))
} else {
  targetTime.value.setMinutes(targetTime.value.getMinutes() + 8)
  sessionStorage.setItem(
    'timerStartTime',
    targetTime.value.getTime().toString()
  )
}

const currentTime = ref(new Date())

const timeRemaining = computed(() => {
  const remainingMilliseconds = targetTime.value - currentTime.value
  return Math.max(remainingMilliseconds, 0)
})

const minutes = computed(() =>
  Math.floor((timeRemaining.value / 1000 / 60) % 60)
)
const seconds = computed(() => Math.floor((timeRemaining.value / 1000) % 60))

const formattedMinutes = computed(() =>
  minutes.value < 10 ? `0${minutes.value}` : minutes.value
)
const formattedSeconds = computed(() =>
  seconds.value < 10 ? `0${seconds.value}` : seconds.value
)

const updateCurrentTime = () => {
  currentTime.value = new Date()
}

onMounted(() => {
  setInterval(updateCurrentTime, 1000)
})
</script>

<style lang="scss" scoped>
.timer-wrapper {
  position: sticky;
  top: 0;
  z-index: 1;
}
.timer {
  position: relative;
  width: 100vw;
  height: 146px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  margin-bottom: 104px;
  z-index: 1;

  &::before {
    content: '';
    background: url('../../../assets/img/paywall/left.svg') no-repeat;
    background-size: contain;
    width: 100px;
    position: absolute;
    left: 0;
    height: calc(100% - 24px);
  }

  &::after {
    content: '';
    background: url('../../../assets/img/paywall/right.svg') no-repeat;
    background-size: contain;
    width: 100px;
    position: absolute;
    right: 0;
    height: calc(100% - 24px);
  }

  &-img {
    width: 54px;
    height: 72px;
    display: flex;
    margin-right: 32px;
  }

  &-title {
    color: #fff;
    font-weight: 700;
    font-size: 40px;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 32px;
    margin-right: 72px;
  }

  &-count {
    display: flex;
    align-items: center;
    gap: 16px;

    &__item {
      width: 90px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.1);
    }
  }

  @media (max-width: 991px) {
    padding: 0 20px;
    &:before,
    &:after {
      content: none;
    }

    &-button {
      max-width: none;
      width: auto;
    }
  }
  @media (max-width: 767px) {
    height: 61px;
    margin-bottom: 56px;
    &-img {
      width: 18px;
      height: 24px;
      margin-right: 8px;
    }
    &-title {
      font-size: 14px;
      gap: 4px;
      margin-right: 15px;
    }
    &-count {
      gap: 4px;
      &__item {
        width: 33px;
        height: 27px;
      }
    }
    &-button {
      height: 40px;
      font-size: 12px;
    }
  }
}
</style>
