import { computed, reactive, toRefs } from 'vue'

const state = reactive({
  isListening: false,
  size: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
})

const setDimensions = () => {
  state.size.width = window.innerWidth
  state.size.height = window.innerHeight
}

const resizeHandler = () => {
  if (window.requestAnimationFrame) {
    window.requestAnimationFrame(setDimensions)
  } else {
    setDimensions()
  }
}

const useWindowResize = () => {
  if (!state.listener) {
    state.isListening = true
    window.addEventListener('resize', resizeHandler)
  }

  const isMobile = computed(() => {
    return state.size.width <= 767
  })

  const isTablet = computed(() => {
    return state.size.width <= 991
  })

  const isMobileHorizontal = computed(() => {
    const isLandscape =
      state.size.width > 360 && state.size.width > state.size.height
    return state.size.width <= 926 && state.size.height <= 428 && isLandscape
  })

  return {
    ...toRefs(state.size),
    isMobile,
    isTablet,
    isMobileHorizontal,
  }
}

export default useWindowResize
