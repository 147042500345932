export const statickTestimonials = [
  {
    author_name: 'Amanda Miller',
    author_picture: require('@/assets/img/user-1.png'),
    text: '“Mental Future’s meditations and therapy course helped me start feeling calmer in just about 4 weeks. Over this period I noticed much more progress than I managed to make in the past 5 years of taking prescription drugs and anti-depressants. Thanks for doing this for people like me!”',
  },
  {
    author_name: 'Andrew Gordon',
    author_picture: require('@/assets/img/user-2.png'),
    text: '"Staying awake all night was something I used to do almost every night. I was just unable to fall asleep at all. Insomnia was slowly decreasing my quality of life and i started having feelings of hopelessness, regret and anxiety. I’m so happy things have changed thanks to Mental Future. My life is much more meaningful now!"',
  },
  {
    author_name: 'Stephanie Allen',
    author_picture: require('@/assets/img/user-3.png'),
    text: '“As a single mother of three it has never been easy for me: I had to work and provide for my children. I often had to hide my emotions and suppress the stress. Now, thanks to Mental Future, I learned how to embrace my emotions and focus more on positive thoughts, which led to me starting to enjoy certain aspects of life more.”',
  },
]

export const appstoreReviews = [
  {
    author: 'AuroraBorealis88qy, 07/16/2023',
    title: 'Finally, restful nights',
    desc: 'As someone who has struggled with insomnia for years, this app has been a game-changer in helping me achieve restful nights.',
  },
  {
    author: 'MichAelRidEr82, 07/18/2023',
    title: "It's like a personal sleep coach",
    desc: 'I’m in love with this amazing app. If I have to describe it in three words, it would be sleep, relaxation, and peace! You can tell how much I enjoy using it because I purchased the lifetime plan.',
  },
  {
    author: 'Taevian Spendleynn, 05/12/2023',
    title: 'The best mental health app',
    desc: "Mental Future is hands down my favorite mental health app! I've experimented with others, but this is the only one I've truly connected with.",
  },
]

export const trustpilotReviews = [
  {
    author: 'Tracey Morgan',
    title: 'Amazing CBT hypnotherapy!',
    desc: 'I love how the app is designed around individual mental health goals, and the tailored plans are spot on. Their approach to Cognitive Behavioral Therapy is both accessible and effective.',
    days: '14 days ago',
  },
  {
    author: 'Cynthia Bailey',
    title: "The best sleep hypnosis I've tried by far!",
    desc: "Where has this app been all my life?! I've been struggling with sleep issues for years, and the sleep meditations on Mental Future have been a lifesaver. The hypnotic sleepcasts put me to sleep in minutes.",
    days: '5 days ago',
  },
  {
    author: 'Pete K.',
    title: 'Game-changing personal plan!',
    desc: 'I find myself reaching for this app daily. The personalized plan is a game-changer; it genuinely felt tailored to my needs. ',
    days: '37 days ago',
  },
]

export const questions = [
  {
    icon: require('@/assets/img/paywall/icons/faq1.png'),
    question: 'Overcome Binge Eating',
    answer:
      'With Mental Future, overcoming binge eating becomes a collaborative journey. This personalized mental health app integrates CBT and hypnotherapy to help you recognize emotional triggers, pinpoint problematic patterns, and offers a suite of therapeutic resources.',
  },
  {
    icon: require('@/assets/img/paywall/icons/faq2.png'),
    question: 'Reduce Anxiety & Panic',
    answer:
      'Mental Future seamlessly blends CBT and hypnotherapy techniques, offering a holistic approach to alleviating anxiety and panic. Through tailored exercises and sessions, this app dives deep into cognitive restructuring, equipping you with tools to challenge distressing thoughts, while its hypnotherapy component aids in achieving profound relaxation, grounding you in moments of heightened stress. Together, these methods forge a pathway to a calmer mind.',
  },
  {
    icon: require('@/assets/img/paywall/icons/faq3.png'),
    question: 'Stop Depression',
    answer:
      'Mental Future merges CBT and hypnotherapy, creating a potent tool against depression. By addressing negative thought patterns through cognitive techniques, and harnessing the transformative power of hypnotherapy for deep emotional healing, this app provides a dual approach to uplift your mood and reignite the joy in everyday moments.',
  },
  {
    icon: require('@/assets/img/paywall/icons/faq4.png'),
    question: 'Sleep Better',
    answer:
      'Mental Future combines CBT and hypnotherapy to optimize your sleep. Through cognitive strategies, it reframes sleep-disruptive thoughts, while the calming influence of hypnotherapy guides you into a deeper, more restful slumber, ensuring rejuvenated mornings.',
  },
  {
    icon: require('@/assets/img/paywall/icons/faq5.png'),
    question: 'Manage ADHD',
    answer:
      'Mental Future blends CBT and hypnotherapy to offer a tailored approach for ADHD management. By addressing impulsivity and inattention with cognitive techniques and enhancing focus through the grounding effects of hypnotherapy, this app crafts a balanced strategy to navigate daily challenges with ADHD.',
  },
  {
    icon: require('@/assets/img/paywall/icons/faq6.png'),
    question: 'Stop Procrastination',
    answer:
      "Mental Future fuses CBT and hypnotherapy, creating an intuitive tool to combat procrastination. Through cognitive strategies, it reshapes delay-inducing beliefs, and with hypnotherapy's motivational boost, the app empowers you to transition from intention to action, making productivity second nature.",
  },
  {
    icon: require('@/assets/img/paywall/icons/faq7.png'),
    question: 'Make More Money',
    answer:
      "Mental Future integrates CBT and hypnotherapy, offering a unique approach to financial growth. By challenging limiting beliefs with cognitive methods and harnessing hypnotherapy's ability to bolster confidence and drive, this app primes your mindset for seizing lucrative opportunities and amplifying your earning potential.",
  },
]

export const peopleAsk = [
  {
    title: 'What happens after I order?',
    desc: 'After you order our AI algorithm creates your optimal personal plan based on your goals and demographic profile. Our team of psychologists and hypnotherapists evaluate your plan and constantly make improvements to the AI algorithm to make sure it is the best fit for you.',
  },
  {
    title: 'How do I get my personal plan?',
    desc: 'Your personal plan will be made available on the website and in the app. This way you can user our AI-powered personal plan and evidence-based expert content to actually bring about the desired change in your thought patterns anbd behaviors. It taps into different aspects of your consciousness, fostering comprehensive mental well-being and personal growth.',
  },
  {
    title: 'How do I get my app',
    desc: 'We will email you a special link to download the app and sign in so that you get immediate premium access.',
  },
  {
    title: 'How do I get my free bonuses?',
    desc: 'The sound therapy section will be available to you after you make a purchase directly in the Mental Future website and app. The ebook and habit building blueprint will be sent to you per e-mail after successful purchase.',
  },
]

export const staticPaywall = {
  headline: "It's Time To Take Care Of Your Mental Future!",
  charts: [
    {
      emoji: '🙂',
      enable: true,
      percentage: '91',
      position: 1,
      time: ['2 weeks'],
      title: 'Happiness over time',
      type: 'Happiness',
    },
    {
      emoji: '😰',
      enable: true,
      percentage: '-84',
      position: 1,
      time: ['4 weeks'],
      title: 'Stress Level',
      type: 'stress level',
    },
    {
      emoji: '😴',
      enable: true,
      percentage: '91',
      position: 1,
      time: ['2 weeks'],
      title: 'Sleep Duration',
      type: 'sleep duration',
    },
    {
      emoji: '😎',
      enable: true,
      percentage: '+79',
      position: 1,
      time: ['4 weeks'],
      title: 'Confidence',
      type: 'Confidence',
    },
  ],
  advantages: [
    {
      title: 'Enhanced Sleep Quality',
      description:
        'With the specialized sleep meditations and calming soundscapes, you can experience deeper, more restful sleep, rejuvenating your body and mind for the day ahead.',
      emoji: '😰',
    },
    {
      title: 'Reduced Stress and Anxiety',
      description:
        "Using the guided meditations, nature sounds, and tailored mental health plans, you'll find it easier to navigate daily challenges, feeling calmer and more centered in your life.",
      emoji: '😌',
    },
    {
      title: 'Better Emotional Regulation',
      description:
        'The Cognitive Behavioral Therapy (CBT) tools empower you to recognize and manage negative thought patterns, paving the way for emotional balance and resilience against external stressors.',
      emoji: '🙂',
    },
    {
      title: 'Personal Growth',
      description:
        "By embracing the daily affirmations and personalized mental health goals, you'll cultivate a growth mindset, deepening your self-awareness and evolving continuously.",
      emoji: '💪',
    },
    {
      title: 'Improved Focus',
      description:
        "If distractions or procrastination have been obstacles for you, the app's tailored plans offer techniques to bolster concentration, allowing you to be more productive.",
      emoji: '👀',
    },
    {
      title: 'Knowledge = Power',
      description:
        "As you delve into the resources, tools, and practices offered, you'll gain a deeper understanding of your mental processes, granting you more agency and control over your mental well-being.",
      emoji: '📚',
    },
  ],
  testimonials: [
    {
      text: '"Mental Future’s meditations and therapy course helped me start feeling calmer in just about 4 weeks. Over this period I noticed much more progress than I managed to make in the past 5 years of taking prescription drugs and anti-depressants. Thanks for doing this for people like me!"',
      author_name: 'Amanda Miller',
      author_picture: require('@/assets/img/user-1.png'),
      position: 5,
    },
    {
      text: '"Staying awake all night was something I used to do almost every night. I was just unable to fall asleep at all. Insomnia was slowly decreasing my quality of life and i started having feelings of hopelessness, regret and anxiety. I’m so happy things have changed thanks to Mental Future. My life is much more meaningful now!"',
      author_name: 'Andrew Gordon',
      author_picture: require('@/assets/img/user-2.png'),
      position: 6,
    },
    {
      text: '“As a single mother of three it has never been easy for me: I had to work and provide for my children. I often had to hide my emotions and suppress the stress. Now, thanks to Mental Future, I learned how to embrace my emotions and focus more on positive thoughts, which led to me starting to enjoy certain aspects of life more.”',
      author_name: 'Stephanie Allen',
      author_picture: require('@/assets/img/user-3.png'),
      position: 7,
    },
  ],
}
